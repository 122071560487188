import React from "react";
import { Link } from "react-router-dom";
import classcat from "classcat";

import { useSelector } from "react-redux"; 
import c from "./leftPanel.module.css";
 
const LeftPanel = ({status, setStatus}) => { 
  const config = useSelector(state => state.config); 

  const onClose = () => {
    setStatus(false);
  }

  if(!config) return null;


  const currentCategory = config.categories.find(c => window.location.pathname.includes(c.category_id));
  
  return (
    <div 
      className={classcat([
        c.leftPanel,
        status && c.active
      ])} 
    >
      <MainCategories 
        categories={config.categories}
        onClose={() => setStatus(false)}
      />
      <div className={c.categoryContent}>
        {
          currentCategory && currentCategory.sub.map(category => {
            return (
              <Link  
                key={"sub-category"+category.category_id}
                className={c.selectedSubCategory}
                to={"/" + currentCategory.category_id + "/" + category.category_id}
                onClick={onClose}
              >
                {category.name}
              </Link>
            )
          })
        }
        {
          !currentCategory && config.categories.map((mainCategory) => {
            return (
              <div key={"left-nav-category-"+mainCategory.category_id}>
                <Link  
                  className={mainCategory.type !== "sub" ? c.subcategory : c.childCategory}
                  to={"/" + mainCategory.category_id }
                  onClick={onClose}
                >
                  {mainCategory.name}
                </Link>
                {
                  
                  mainCategory.sub.map((subCategory, index)=> {
                    return (
                      <Link 
                        key={"subcategory-"+index}
                        className={subCategory.type !== "sub" ? c.subcategory : c.childCategory}
                        to={"/" + mainCategory.category_id + "/" + subCategory.category_id}
                        onClick={onClose}
                      >
                        {subCategory.name}
                      </Link>
                      ) 
                  })
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
};



const MainCategories = ({categories, onClose}) => {
  return (
    <div className={c.mainCategories}>
      {
        categories.map((category, index) => (
          <Link
            key={"main-category-" + index}
            className={window.location.pathname.includes(category.category_id) ? c.active : ""}
            to={"/" + category.category_id }
            onClick={onClose}
          >
            {category.name}
          </Link>
        ))
      }
    </div>
  );
}


export default LeftPanel;