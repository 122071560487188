import React, { useEffect, useRef } from "react"; 
import classcat from "classcat";
 
import Loading from "./../../../components/Loading"; 
import Input from "./../../../components/Input"; 

import c from "./searchPopup.module.css";
import { Link } from "react-router-dom";
 
 
const SearchPopup = ({
  searchText,
  listedCategories,
  listedProducts,
  onClose,
  setSearchText,
  setPopupStatus,
  searchAction,
  loading
}) => {
  const modal = useRef(); 
  
  useEffect(() => {
    if(modal.current)
      modal.current.style.opacity = 1;

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    }
  }, []); //eslint-disable-line
   

  return (
    <div 
      ref={modal} 
      className={c.modal} 
      data-close={true}
      onClick={e => {
        if(e.target.dataset.close) onClose()
      }}>
      <div className={c.container}> 

        {
          loading && <Loading inline/>
        }
        <div className={c.mobileSearchInput}>
          <div className={c.searchInputContainer}>
            <Input 
              placeholder="Sarissa'da ara"
              data-popup={true}
              className={classcat([c.searchInput])}
              value={searchText}
              onFocus={() => setPopupStatus(true)} 
              onChange={e => {
                setSearchText(e.target.value); 
              }}
              onKeyUp={e => {
                if(window.searchTimeout) clearTimeout(window.searchTimeout);
                window.searchTimeout = setTimeout(() => {
                  searchAction(e.target.value.toLowerCase());
                }, 500);
              }} 
            /> 
            {
              searchText.length !== 0 &&
                <button 
                  className={c.clearButton}  
                  dangerouslySetInnerHTML={{__html: "&times"}}
                  onClick={() => {
                    setSearchText("");
                    searchAction("");
                  }}
                />
            } 
            
          </div>
          <button onClick={onClose}>Vazgeç</button>
        </div>
        <div className={c.left}>
          <div className={c.title}>
            Kategoriler
          </div>
            
            <div className={c.categoryList}>
              {
                listedCategories.length === 0 && <div className={c.categoryEmptyState}>
                  Arama kriterlerinize uygun kategori bulunamadı
                </div>
              }
              {
                listedCategories.map(category => {
                  let link = "/" + category.category;
                  return <Link 
                            onClick={onClose}
                            className={classcat([c.categoryLink, category.subcategory && c.subcategory])} 
                            key={"category-link-" + category.category + "-" + category.subcategory}
                            to={link}
                          >{category.name}</Link>
                })

              }
          </div>
          
        </div>
        <div className={c.right}>
          <div className={c.title}>
            Ürünler
            <button 
              className={c.desktopCloseButton}
              onClick={onClose}
              dangerouslySetInnerHTML={{__html: "&times"}}
            />
          </div>
          <div className={c.productList}>
            {
              listedProducts.length === 0 && (
                <div className={c.productEmptyState}>
                  Arama kriterlerinize uygun ürün bulunamadı
                </div>
              )
            }
            {
              listedProducts.map(product => { 
                  const sPrice = product.price.split(",");
                  let price = sPrice[0]; 
                  let priceSuffix = false;

                  if(sPrice.length > 1){
                    priceSuffix = "," + sPrice[1];
                  }

                  let discount, discountSuffix; 
                  if(product.discounted_price){
                    product.discounted_price = product.discounted_price+""; 
                    const sDiscount = product.discounted_price.split(",");
                    discount = sDiscount[0];
                    
                    if(sDiscount.length > 1){
                      discountSuffix = "," + sDiscount[1];
                    }
                  }
 
 
                return <Link  
                          onClick={onClose}
                          to={`/${product.category}/${product.subcategory}/${product.product_id}/${product.image.colorId}`}
                          className={c.product} key={"searched-product-" + product.id}  
                        >
                          <img src={window.getBaseUrl(product.image.path)} alt=""/>
                          <div className={c.productRight}>
                              <div className={c.productName} >{product.name}</div>
                              <div className={classcat([c.priceField, product.discount !== 0 && c.discounted])}> 
                                <div className={c.price} >
                                  {price}
                                  { priceSuffix && <small>{priceSuffix}</small> }
                                  <small>TL</small>
                                </div>
                                {
                                  product.discounted_price  && 
                                    <div className={c.discount} >
                                      {discount}
                                      <small>{discountSuffix}</small>
                                      <small>TL</small>
                                    </div>
                                }
                              </div>
                          </div>
                        </Link> 
              }) 
            }
          </div>

        </div>

      </div>
    </div>
  )
}
 

 

export default SearchPopup;