import React from "react";
import classcat from "classcat";

import { AsYouType } from "libphonenumber-js";
import c from "./input.module.css";

const Input = ({
  label = "",
  value,
  onChange,
  className,
  warn,
  warnText
}) => {

  return (
    <label className={classcat([c.container, className])}>
      <span className={c.label}>{label}</span>
      <div className={classcat([c.input, warn && c.inputWarning])} >
        <span>0</span>
        <input 
          autoComplete={"none"}
          type="tel" 
          value={value}
          placeholder="___ __ __"
          onChange={e => {
            let val = e.target.value;

            if(val.length < 14) onChange(new AsYouType("TR").input(e.target.value))
          }}
        />
      </div>
      <div className={c.warning}>{warn && warnText}</div>   
    </label>
  )
};

export default Input;