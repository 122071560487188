export const setUser = (payload) => {
  // let cart = [];
  // console.log("user from payload", payload);

  if(payload){
    if(typeof payload.favorites === "string" && payload.favorites.length > 1)
      payload.favorites = JSON.parse(payload.favorites); 
    else
      payload.favorites = [];
  } 

  localStorage.setItem("u-t", payload.token); 
  localStorage.setItem("u-d", window.btoa(encodeURIComponent(JSON.stringify(payload))));
   

  return {
    type: "SET_USER",
    payload
  }
}

 
 

export const setAddressList = (payload) => {
  return {
    type: "SET_ADDRESS_LIST",
    payload
  }
}









export const toggleFavoriteState = (payload) => {
  const user = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("u-d"))));
  
   
  const index = user.favorites.indexOf(parseInt(payload));
   
 
  if (index > -1) {
    user.favorites.splice(index, 1);
  }else{  
    user.favorites.push(parseInt(payload));
  }   
  localStorage.setItem("u-d", window.btoa(encodeURIComponent(JSON.stringify(user))));
 
  return {
    type: "SET_FAVORITES",
    payload: user.favorites
  }
}


  
export const addToCart = (product) => {
  return {
    type: "ADD_TO_CART",
    payload: product
  }
}



