import React, { useState } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import PinInput from "react-pin-input";

import ModalTemplate from "./../../../ModalTemplate";
import Button from "./../../../Button";

import { setLoading, setSuccessModal } from "./../../../../actions/elements";
import { setUser } from "./../../../../actions/user";
import { updateValidation } from "./../../../../services";
import c from "./verificationModal.module.css";

 
function VerificationModal({onClose, modalData}){ 
  const dispatch = useDispatch();
  const [pinCode, setPinCode] = useState(""); 
  const [errorMsg, setErrorMsg] = useState("");
  
  const user = useSelector(selector => selector.user);

  const { data } = modalData; 
   

  const handleSubmit = () => {
    if(pinCode.length !== 6) return;

    dispatch(setLoading(true));
     
    data.pin_code = pinCode;
    updateValidation(data)
    .then(res => {
      console.log(res.data);
      if(res.data.status !== "OK"){
        setErrorMsg(res.data.message || "Girilen pin kodu geçersizdir.");
        return;
      }
      
      delete data.user;
      dispatch(setUser({
        ...user,
        ...data
      }));
      dispatch(setSuccessModal({
        message: "Bilgilerin başarıyla kaydedildi."
      }));
    }) 
    .catch(err => {
      setErrorMsg("Beklenmedik bir hata oluştu");
    })
    .finally(() => {
      dispatch(setLoading(false)); 
    })
  }
 
  return (
    <ModalTemplate
      onClose={() => {
        onClose()
        if(modalData.onClose) modalData.onClose();
      }}
    >
      <div className={c.title} >Hesap Doğrulaması Gerekli!</div>
      <div className={c.subtitle}>Değişiklikleri kaydedebilmek için girilen yeni e-posta adresini doğrulamanız gerekmektedir.</div> 
      <label className={c.label}>E-posta adresinize gelen pin kodu</label>

      <PinInput 
        length={6} 
        initialValue=""
        
        onChange={(value, index) => {
          setPinCode(value);  
        }} 
        type="numeric" 
        style={{
          marginBottom: "20px",
          marginTop: 10,
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}  
        inputStyle={{
          width: 42, 
          height: 42,
          margin: "0 4px", 
          borderRadius: 4,
          background: "var(--lightgray)",
          borderColor: "rgb(220, 220, 225)",
          boxSizing: "border-box",
          fontSize: "14px"
        }} 
        inputFocusStyle={{background: 'rgb(230, 230, 230)'}}
      /> 
      
      {
        errorMsg && <div className={c.errorMsg}>{errorMsg}</div>
      }
      
      <Button 
        text="Doğrula"
        onClick={handleSubmit}
      />
      
    </ModalTemplate>
  )
}

export  default VerificationModal;