function cart(state = false, action){
  switch(action.type){
    case "SET_CART": 
      return action.payload;
    case "RESET_CART": 
      return false;

    default: 
      return state;
  } 
}

export default cart;
