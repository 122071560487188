import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";

import c from "./modal.module.css";

import { setDefaultModal } from "./../../actions/elements";

function DefaultModal(){
  const modal = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if(modal.current) modal.current.style.opacity = 1;
  }); //eslint-disable-line
  
  const modalData = useSelector(selector => selector.elements.defaultModal);
  if(!modalData) return null;

  const closeModal = (event) => {
    if(event && event.target.dataset.dont_close) return;
    
    dispatch(setDefaultModal(false));
  }

  return (
    <div 
      className={c.container} 
      ref={modal}
      onClick={closeModal}  
    >
      <div className={c.popup} data-dont_close={true} >
        {
          !modalData.hideCloseButton &&
            <div 
              onClick={() => {
                if(modalData.onClose) modalData.onClose();

                if(modalData.onXButton) modalData.onXButton();
                closeModal();
              }}
              className={c.close}
              dangerouslySetInnerHTML={{__html: "&times"}} />
        }

        {
          modalData.icon &&
            <img className={c.icon} src={modalData.icon} alt=""/>
        }
        {
          modalData.image &&
            <img src={modalData.image} className={c.image} alt="" />
        }
        {
          modalData.title &&
            <div className={c.title} >
              {modalData.title}
            </div>
        }
        
        {
          modalData.message &&
            <div className={c.message} dangerouslySetInnerHTML={{__html: modalData.message}} >
            </div>
        }
        
        { 
          modalData.button &&
            <Button
              text={modalData.button}
              className={c.button}
              onClick={() => {
                if(modalData.onClose) modalData.onClose();
                
                if(modalData.onClick) modalData.onClick();

                if(!modalData.preventCloseOnClick) closeModal();
              }}
            />
        }
        { 
          modalData.secondButton &&
            <Button
              text={modalData.secondButton}
              className={c.button}
              style={{marginTop: 10}}
              onClick={() => {
                if(modalData.onClose) modalData.onClose();
                
                if(modalData.onClick) modalData.secondClick();

                if(!modalData.preventCloseOnClick) closeModal();
              }}
            />
        }
        { 
          modalData.thirdButton &&
            <Button
              text={modalData.thirdButton}
              className={c.button}
              style={{marginTop: 10}}
              onClick={() => {
                if(modalData.onClose) modalData.onClose();
                
                if(modalData.onClick) modalData.thirdClick();

                if(!modalData.preventCloseOnClick) closeModal();
              }}
            />
        }
        {
          modalData.cancel &&
            <Button
              text={modalData.cancel || "Vazgeç"}
              secondary
              className={c.cancel}
              onClick={() => {
                if(modalData.onClose) modalData.onClose();
                
                closeModal();
                if(modalData.onCancel) modalData.onCancel();
              }}
            />
        }

      </div>
    </div>
  )
}

export  default DefaultModal;