import React from "react";

import c from "./cookie.module.css";
import { Link } from "react-router-dom";


export default function Cookie({onClose}){

  if(window.location.href.includes("localhost")) return false;

  return (
    <div className={c.container}>
      <div className={c.content}>
          Hizmetlerimizden en iyi şekilde faydalanabilmeniz için çerezler kullanıyoruz. 
          sarissa.App’i kullanarak çerezlere izin vermiş olursunuz. 
          <br />
          Çerezler hakkında detaylı bilgi almak için: 
           <Link to="/cerez-politikasi/" >Çerez Politikası</Link>
      </div>
      <button 
        className={c.close} 
        onClick={onClose}
      >&times;</button>
    </div> 
  ) 
} 

