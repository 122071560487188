import React from "react";


const EyeIcon = ({fill}) => {

  return(
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      version="1.0" 
      width="64.000000pt" 
      height="64.000000pt" 
      viewBox="0 0 64.000000 64.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
        <path d="M193 490 c-65 -30 -193 -143 -193 -170 0 -28 129 -141 195 -171 131 -59 255 -29 380 93 36 35 65 70 65 78 0 8 -29 43 -65 78 -125 122 -252 152 -382 92z m238 -38 c26 -12 73 -47 105 -77 l59 -55 -59 -55 c-81 -75 -135 -100 -216 -100 -81 0 -134 25 -216 99 l-59 54 30 32 c43 45 117 98 162 115 52 20 138 14 194 -13z"/>
        <path d="M271 433 c-102 -53 -101 -175 2 -226 65 -33 145 7 168 83 10 32 9 44 -7 76 -35 71 -103 98 -163 67z m106 -55 c51 -48 15 -138 -56 -138 -47 0 -81 32 -81 79 0 72 84 109 137 59z"/>
      </g>
    </svg>

  );
}


export default EyeIcon;