import React, { useRef, useEffect } from "react"; 
import classcat from "classcat";

import c from "./modal.module.css";
 
function ModalTemplate({
  onClose, 
  hideCloseButton, 
  children, 
  closeButtonClass,
  popupClass,
  title
}){ 
  const modal = useRef();
     
  useEffect(() => {
    if(modal.current) modal.current.style.opacity = 1;
  }); //eslint-disable-line
 

  return (
    <div className={c.container} ref={modal}>

      <div className={classcat([c.popup, popupClass])} >
        <div className={c.top}>
          <div className={c.title}>
            {title}
          </div>
          {
            !hideCloseButton &&
              <div 
                onClick={onClose}
                className={classcat([c.close, closeButtonClass])}
                dangerouslySetInnerHTML={{__html: "&times"}} />
          }
        </div>
        <div className={c.content}>
          {children}
        </div>
      </div>
    </div>
  )
}

export  default ModalTemplate;