import React from "react";
import classcat from "classcat";

import siteLogo from "./../../images/sarissa-logo.svg";

import c from "./loading.module.css";

  
const Loading = ({className, inline}) => {

  return (
    <div className={classcat([c.container, className, inline && c.inline]) }>
      <img src={siteLogo} alt=""/>
    </div>
  );
};

export default Loading;