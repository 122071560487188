import React, { useEffect, useState } from 'react';
import { AsYouType } from "libphonenumber-js";

import { useDispatch, useSelector } from "react-redux";
import { setErrorModal, setSuccessModal } from '../../../../actions/elements';
import { setAddressList } from '../../../../actions/user';

import ModalTemplate from "./../../../ModalTemplate";
import Input from "./../../../Input";
import PhoneInput from "./../../../PhoneInput";

import sendRequest from "./../../../../services";

import c from "./address.module.css";
import Button from '../../../Button';



export const AddressModal = ({onClose, modalData}) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);

  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);
  const [attempted, setAttempted] = useState(false);

  const [name, setName] = useState(modalData.data ? modalData.data.name : "");
  const [surname, setSurname] = useState(modalData.data ? modalData.data.surname : "");
  const [phone, setPhone] = useState(modalData.data ? new AsYouType("TR").input(modalData.data.phone || "") : "");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [title, setTitle] = useState(modalData.data ? modalData.data.title : "");
  const [address, setAddress] = useState(modalData.data ? modalData.data.address : "");


  useEffect(() => {
    const cities = require("./cityData/cities.json");
    setCities(cities);
    const counties = require("./cityData/counties.json");
    setCounties(counties);
    
    if(modalData.data){
      const city = cities.find(c => c.name === modalData.data.city);
      if(city) setCity(city.id);

      setCounty(modalData.data.county);
    }    

    document.querySelector("#root").classList.add("block-for-modal")
    return () => {
      document.querySelector("#root").classList.remove("block-for-modal")
    }
  }, []); //eslint-disable-line



  const validatePhone = () => {
    return phone.length === 13 && phone.startsWith("5");
  }
 

  const handleSubmit = () => {
    if(!user){
      dispatch(setErrorModal({
        title: "Kullanıcı bilgisi alınamadı",
        message: "Lütfen tekrar oturum açın"
      }));
    }

    setAttempted(true);
    if(!name.length || !surname.length|| !validatePhone() || !title.length || !address.length || city === "none" || county === "none"){
      return;
    }

    const requestBody = {
      token: user.token,
      name,
      surname,
      phone,
      city: cities.find(c => c.id === city).name, 
      county,
      address,
      title
    };

    if(modalData.data)
      requestBody.id = modalData.data.id;
 
    sendRequest.post(
      modalData.data ? "consumer/update_address" : "consumer/save_address", requestBody
    ).then(res => {
      console.log(res.data);
      if(res.data.status !== "OK"){
        dispatch(setErrorModal({
          message: res.data.message
        }));
        return;
      }
      
      if(typeof res.data.address_list === "string")
        res.data.address_list = JSON.parse(res.data.address_list);

      dispatch(setAddressList(res.data.address_list));
      dispatch(setSuccessModal({
        title: "Başarılı",
        message: res.data.message
      }));
      onClose();
    })
    .catch(err => {
      dispatch(setErrorModal());
    })

  }


  return (
    <ModalTemplate
      onClose={() => {
        onClose()
        if(modalData.onClose) modalData.onClose();
      }}
      closeButtonClass={c.close}
      popupClass={c.popup}
    >
      <div className={c.titleField}>
        <span className={c.title} >
          {modalData.data ? "Adres Düzenle": "Yeni Adres Oluştur"}
        </span>
      </div>
      <div className={c.content}>
        <Input 
          label="ADINIZ*"
          className={c.input}
          value={name}
          onChange={e => setName(e.target.value)}
          warnText="İsim alanı girilmedi"
          warn={attempted && !name.length}
        />
        <Input 
          label="SOYADINIZ*"
          className={c.input}
          value={surname}
          onChange={e => setSurname(e.target.value)}
          warnText="Soyisim alanı girilmedi"
          warn={attempted && !surname.length}
        />

        <PhoneInput 
          label="CEP TELEFONU*"
          className={c.phoneInput}
          value={phone}
          onChange={setPhone}
          warnText={!phone.length ? "Telefon numarası girilmedi" : "Lütfen geçerli bir telefon giriniz"}
          warn={attempted && !validatePhone()}
        />

        <label className={c.selectLabel} style={{width: "47.5%"}}>
          İL*
          <select 
            value={city}
            onChange={e => {
              setCity(e.target.value)
              setCounty("none")
            }}
            className={c.select}
          >
            <option value="none">Lütfen il seçin</option>
            {
              cities.map((city) => {
                return <option key={"city-opt-"+city.id} value={city.id} >{city.name}</option>
              })
            }
          </select>
          <span className={c.error} >
            {(attempted && city === "none")  && "İl seçilmedi"}
          </span>
        </label>

        <label className={c.selectLabel} style={{width: "47.5%"}}>
          İLÇE*
          <select 
            value={county}
            onChange={e => setCounty(e.target.value)}
            className={c.select}
          >
            <option value="none">Lütfen ilçe seçin</option>
            {
              city !== "none" && 
                counties.filter(c => c.il_id === city)
                .map(county => {
                  return <option key={"county-option-"+county.id} >{county.name}</option>
                })
            }
          </select>
          <span className={c.error} >
            {(attempted && county === "none")  && "İlçe seçilmedi"}
          </span>
        </label>

        <label className={c.label}>ADRES*</label>
        <textarea 
          value={address}
          onChange={e => setAddress(e.target.value)}
          className={c.textarea}
        ></textarea>
        {
          (attempted && !address.length) &&
          <span className={c.error}>Adres girilmedi</span>
        }

        <Input 
          label="ADRES BAŞLIĞI*"
          className={c.addressInput}
          value={title}
          onChange={e => setTitle(e.target.value)}
          warnText="Adres başlığı girilmelidir."
          warn={attempted && !title.length}
        />

        <Button
          text="KAYDET"
          className={c.submit}
          onClick={handleSubmit}
        />
        
      </div>
      
    </ModalTemplate>
  )
}
