import React from "react";
import { useDispatch, useSelector } from "react-redux"; 

import { setModal } from "./../../actions/elements";
import { AddressModal } from "./types/AdressModal";
import VerificationModal from "./types/VerificationModal";
import HtmlModal from "./types/HtmlModal";

function Modal(){
  const dispatch = useDispatch();
  
  const modalData = useSelector(selector => selector.elements.modal);
  if(!modalData) return null; 

  const closeModal = () => {
    dispatch(setModal(false));
  }
 
  switch (modalData.type) {
    case "verification":
      return  <VerificationModal
                onClose={closeModal}
                modalData={modalData}
              />
    case "address": 
      return  <AddressModal 
                onClose={closeModal}
                modalData={modalData}
              />
    case "html": 
      return  <HtmlModal 
                onClose={closeModal}
                modalData={modalData}
              />
  
    default:
      return false;
  }

}

export  default Modal;