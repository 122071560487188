export const setCart = (payload) => {  
  return {
    type: "SET_CART",
    payload 
  }
}



export const setRawCart = (payload) => {
  // if(payload){
  //   localStorage.setItem("u-c", window.btoa(encodeURIComponent(JSON.stringify(payload))));
  // }else{
  //   localStorage.removeItem("u-c");
  // }

  return {
    type: "SET_RAW_CART",
    payload 
  }
}


  
export const resetCart = (payload) => {
  // localStorage.removeItem("u-c"); 
  return {
    type: "RESET_CART",
    payload 
  }
}
  
export const addToCart = (product) => {
  return {
    type: "ADD_TO_CART",
    payload: product 
  }
}

export const setQuantity = (product, quantity) => { 
  return {
    type: "SET_QUANTITY",
    product,
    quantity
  }
}
 
export const removeFromCart = (product) => { 
  return {
    type: "DELETE_PRODUCT",
    product, 
  }
}
 
export const setCartGift = (product) => { 
  return {
    type: "SET_CART_GIFT",
    product, 
  }
}



