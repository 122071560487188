import request  from "./config";

function convertToFormData(data){
  const formData = new FormData();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return formData;
}


const sendRequest = {
  post: function(url, data) {
    return request.request({
      url,
      method: "post",
      data: data && convertToFormData(data)
    })
  },
  get: function(url) {
    return request.request({
      url,
      method: "get", 
    })
  } 
}

export default sendRequest;
 

export function getConfig() {
  return request.request({
    method: "get",
    url: "config"
  });
}

export function getFilterOptions() {
  return request.request({
    method: "get",
    url: "config/get_filter_options"
  });
}


//  ---------  USER FLOW START --------------
export function saveUser(data) {
  return request.request({
    method: "post",
    url: "consumer/create",
    data: convertToFormData(data)
  });
}
 

export function login(data) {
  return request.request({
    method: "post",
    url: "consumer/authenticate",
    data: convertToFormData(data)
  });
}
 

export function updateUser(data) {
  return request.request({
    method: "post",
    url: "consumer/update",
    data: convertToFormData(data)
  });
}
 
export function validateAccount(data) {
  return request.request({
    method: "post",
    url: "consumer/verification",
    data: convertToFormData(data)
  });
}
 
export function updateValidation(data) {
  return request.request({
    method: "post",
    url: "consumer/verification_update",
    data: convertToFormData(data)
  });
}
 
export function resendVerificationMail(data) {
  return request.request({
    method: "post",
    url: "consumer/resend_verification_mail",
    data: convertToFormData(data)
  });
}
 
export function saveAddress(data) {
  return request.request({
    method: "post",
    url: "consumer/save_address",
    data: convertToFormData(data)
  });
}
 
export function editAddress(data) {
  return request.request({
    method: "post",
    url: "consumer/save_address",
    data: convertToFormData(data)
  });
}
 
export function deleteAddress(data) {
  return request.request({
    method: "post",
    url: "consumer/delete_address",
    data: convertToFormData(data)
  });
}
 
export function getFavorites(data) {
  return request.request({
    method: "post",
    url: "consumer/get_favorites",
    data: convertToFormData(data)
  });
}
 
export function saveCartData(data) {
  return request.request({
    method: "post",
    url: "consumer/save_cart",
    data: convertToFormData(data)
  });
}  
 
export function getCart(data) {
  return request.request({
    method: "post",
    url: "cart/get_cart",
    data: convertToFormData(data)
  });
}

//  ---------  USER FLOW END --------------

 

 

export function getProduct(productId) {
  return request.request({
    method: "get",
    url: "product/get_product/" + productId,
  });
}

export function viewProduct(productId) {
  return request.request({
    method: "get",
    url: "product/view_product/" + productId,
  });
}


export function getProducts(data) {
  return request.request({
    method: "post",
    url: "product/get_products",
    data: convertToFormData(data)
  });
}
 
 
// export function toggleFavoriteProduct(id) {
//   return request.request({
//     method: "get",
//     url: "consumer/toggle_favorite_product/" + id,
//   });
// }

export function toggleFavoriteProduct(data) {
  return request.request({
    method: "post",
    url: "consumer/toggle_favorite_product",
    data: convertToFormData(data)
  });
}
 
export function buyRequest(data) {
  return request.request({
    method: "post",
    url: "payment/buy",
    data: convertToFormData(data)
  });
}
 


 