import React, { useState, useEffect } from "react"; 
import classcat from "classcat";

import { useSelector } from "react-redux"; 


import cartIcon from "./../../../images/search.svg";
import Input from "./../../../components/Input"; 

import sendRequest from "./../../../services";
import c from "./searchField.module.css";
import SearchPopup from "../SearchPopup";
 
 
const SearchField = () => { 
  // const [status, setStatus] = useState(false); 
   
  const config = useSelector(state => state.config);
  const [listedCategories, setListedCategories] = useState(
    config.categories.map(c => { return {category: c.category_id, name: c.name} })
  );
  const [listedProducts, setListedProducts] = useState([]);
  
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupLoading, setPopupLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

 

  useEffect(() => {
    if(!popupStatus) return;  

    sendRequest.post("config/search",{
      key: searchText
    })
    .then(res => {
      console.log(res.data);
      if(res.data.status === "OK"){ 
        setListedProducts(res.data.products);
      }
    })
    .finally(() => setPopupLoading(false))
    
  }, [popupStatus]); //eslint-disable-line
 


  const searchAction = text => {
    let findedCategories = [];
    setPopupLoading(true);

    config.categories.forEach(category => {
      if(category.name.toLowerCase().includes(text)){
        findedCategories.push({
          category: category.category_id,
          name: category.name
        });
      }


      for (let index = 0; index < category.sub.length; index++) {
        const subcategory = category.sub[index];

        if(subcategory.name.toLowerCase().includes(text)){
          findedCategories.push({
            category: category.category_id,
            subcategory: subcategory.category_id,
            name: subcategory.name
          })
        } 
      } 
    });
     
    setListedCategories(findedCategories);

    sendRequest.post("config/search",{
      key: searchText
    })
    .then(res => {
      console.log(res.data);
      if(res.data.status === "OK"){
        setListedProducts(res.data.products);
      }
    })
    .finally(() => setPopupLoading(false))

 
  }

  return (
    <div className={c.container}>
      <img  
        src={cartIcon}
        className={c.mobileCartIcon}
        style={{height: 35, cursor: "pointer"}}
        alt=""
        onClick={() => setPopupStatus(!popupStatus)}
      />  
      <Input 
        placeholder="Sarissa'da ara"
        data-popup={true}
        className={classcat([c.searchInput])} 
        value={searchText}
        onFocus={() => setPopupStatus(true)}
        name={"search"}
        autocomplete="search"
        onChange={e => {
          setSearchText(e.target.value); 
        }}

        onKeyUp={e => {
          if(window.searchTimeout) clearTimeout(window.searchTimeout);
          window.searchTimeout = setTimeout(() => {
            searchAction(e.target.value.toLowerCase());
          }, 500);
        }} 
      />  
      {
        popupStatus && (
          <SearchPopup 
            searchText={searchText}
            searchAction={searchAction}
            listedCategories={listedCategories}
            listedProducts={listedProducts}
            onClose={() => setPopupStatus(false)}
            setPopupStatus={setPopupStatus}
            setSearchText={setSearchText}
            loading={popupLoading}
          />
        )
      }
  
 
    </div>
  )
}
 
 


export default SearchField;