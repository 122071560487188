import React from "react";  

import c from "./htmlModal.module.css";

 
export default function HtmlModal({onClose, modalData}){ 

  return (
    <div className={c.modal} > 
      <div className={c.popup}> 
        <div 
          className={c.close}
          dangerouslySetInnerHTML={{__html: "&times"}} 
          onClick={() => {
            onClose();
            if(modalData.onClose) modalData.onClose();
          }}
        />
        <div 
          className={c.content}
          dangerouslySetInnerHTML={{__html: modalData.html}} 
          
        />
      </div>

    </div>
  )
}
