import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setCart, removeFromCart } from "./../../../actions/cart";
import { setErrorModal } from "./../../../actions/elements";

import Button from "../../Button";
 
import Loading from "./../../Loading";
import cartIcon from "./../../../images/sepet-4.png";

import { getCart } from "./../../../services";
import c from "./cartField.module.css";
 

const CartField = ({
  rawCart, 
  cart, 
  cartLength,
  popupStatus,
  setPopupStatus
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
     
  const handleClick = () => {
    if(window.innerWidth < 600){
      history.push("/sepetim");
    }else{
      setPopupStatus(!popupStatus);
    }
  }

 
  useEffect(() => {   
    let cartLength = 0;
    cart && cart.products.forEach(product => {
      cartLength += product.quantity;
    });

    let rawCartLength = 0;
    rawCart.forEach(product => {
      rawCartLength += product.quantity;
    });


    if(popupStatus && (!cart || (cartLength !== rawCartLength))){  
      getCart({ 
        cart: JSON.stringify(rawCart)
      })
      .then(res => {
        if(res.data.status === "OK"){
          dispatch(setCart(res.data.cart));
        }else{
          dispatch(setErrorModal({
            message: res.data.message
          }));
          setPopupStatus(false);
        }
      })
    } 
  }, [cart, popupStatus, rawCart]); //eslint-disable-line
 

  useEffect(() => {
    if(popupStatus){
      window.onclick = e => { 
        if(!window.isDescendant(e.target, "nav-cart-popup") && !e.target.dataset.popup)
          setPopupStatus(false);
      }
    }else{
      window.onclick = null;
    }
  }, [popupStatus]); //eslint-disable-line


  const handleProductDelete = product => {
    dispatch(removeFromCart(product));
    dispatch(setCart(false));  
  }

  const handleEmptyStateClick = () => {
    history.push("/");
    setPopupStatus(false);
  }

    
 
  return (
    <div className={c.cartIcon}>
      <img 
        src={cartIcon}
        style={{height: 35, cursor: "pointer"}}
        alt=""
        onClick={handleClick}
      /> 
      <div 
        className={c.number} 
        onClick={handleClick}
      >
        {cartLength}
      </div>

      {
        popupStatus && 
          <div className={c.popup} id="nav-cart-popup" style={{height: cartLength === 0 ? "230px" : "initial"}}>
            {
              cartLength === 0 && <EmptyState  onClick={handleEmptyStateClick}/> 
            }
            {
              (!cart || cart === "update") &&  <Loading className={c.loading} /> 
            }
            {
              (cart && cart !== "update")  && (
                <> 
                  <div className={c.title}>
                    Sepetinizdeki Ürünler
                    <span className={c.cartQuantity}>{cartLength} ürün</span>
                  </div>
                  <div className={c.list}>
                    {
                      cart.products.map((product, index) => {
                        return <CartProduct
                                  key={"popup-cart-product-"+product.sku + product.variant}
                                  data={product}
                                  onDelete={handleProductDelete}
                                />
                      })
                    }
                  </div>
                  <div className={c.bottomRow}>
                    <b>Toplam (KDV Dahil)</b>
                    <b>{cart.total_price} TL</b>
                  </div>
                  <div className={c.bottomRow}>
                    <span>Kargo</span>
                    <span>Ücretsiz</span>
                  </div>
                  <div className={c.bottomRow}>
                    <b>Ödenecek Tutar</b>
                    <b>{cart.discounted_price} TL</b>
                  </div>
                  <div className={c.bottomRow}>
                    <Button
                      text="Sepeti Görüntüle"
                      secondary
                      className={c.cartButton}
                      onClick={() => {
                        history.push("/sepetim")
                        setPopupStatus(false);
                      }}
                    />
                    <Button
                      text="Siparişi Tamamla"
                      className={c.buyButton}
                      onClick={() => {
                        history.push("/siparisi-tamamla")
                        setPopupStatus(false);
                      }} 
                    />
                  </div>
                </>
              )
            }
          </div> 
      }
    </div>
  )
}
 


const CartProduct = ({data, onDelete}) => {
  if(typeof data.image === "string")
    data.image = JSON.parse(data.image);
 
  const redirect = () => {
    console.log(data);
  }

  return (
    <div className={c.product}>
      <img 
        className={c.image} 
        src={window.getBaseUrl(data.image.path)} 
        onClick={redirect}
        alt=""
      />

      <div className={c.productContent}>
        <div className={c.name}>{data.name}</div>
        <div className={c.info}>
          Adet: {data.quantity}  
          <span style={{marginLeft: 10}}>Beden: {data.size}</span>  
        </div>
        <b className={c.price}>
          <span style={{textDecoration: data.discounted_price ? "line-through" : "normal"}} >{data.price}TL</span>
          {
            data.discounted_price &&  
              <div className={c.discountPrice}>{data.discounted_price}TL</div>  
          }
        </b>
      </div>
      
      <div className={c.deleteButtonContainer}>
        <div 
          className={c.delete}  
          onClick={() => onDelete(data)}
          data-popup={true}
        >
          <img src={require("./../../../images/trash.svg").default}  alt="trash"/>
        </div>
        {
          data.gift && 
            <img 
              className={c.giftIcon} 
              src={require("./../../../images/gift.svg").default} 
              alt="gift"  
            />
        }

      </div>
    </div>
  )
}


const EmptyState = ({onClick}) => (
  <div className={c.emptyState}>
    <h3>Alışveriş Sepetiniz Boş</h3>
    <span>
      Üst menüden bir kategori seçerek ürünleri inceleyebilir, ya da sizin için önerdiğimiz ürünlere aşağıdaki listeden göz atabilirsiniz.
    </span>
    <br />
    <Button  
      className={c.emptyStateButton}
      text="Alışverişe Devam Et"
      onClick={onClick}
    />
  </div>
)


export default CartField;