import React, {useState} from "react";
import classcat from "classcat";

import EyeIcon  from "./../../images/Eye.js";

import c from "./input.module.css";

const Input = ({
  label = "",
  value,
  onChange,
  placeholder,
  type = "text",
  className,
  warn,
  warnText,
  onKeyUp,
  onFocus,
  refrence,
  name,
  autoCapitalize, 
},props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePaswordShow = () => {
    setShowPassword(!showPassword);
  }

  let tempType = type;
  if(type === "password" && showPassword)
    tempType = "text";
 
  return (
    <label className={classcat([c.container, className])}>
      <span className={c.label}>{label}</span>
      <input 
        ref={refrence}
        autoComplete={"none"}
        type={tempType} 
        value={value}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder} 
        autoCorrect={props.autocomplete}
        autoCapitalize={autoCapitalize}
        name={name}
        className={classcat([c.input, warn && c.inputWarning])} 
      />
        <div className={c.warning}>{warn && warnText}</div> 
      {
        type === "password" && 
          <button 
            onClick={handlePaswordShow}
            className={c.showButton} 
          >
            <EyeIcon 
              fill={showPassword ? "black" : "gray"}
            />
          </button>
      } 
    </label>
  )
};

export default Input;