import axios from "axios";

const headerDetail = {};


const request = axios.create({
  baseURL: window.env === "test" ? "https://test.sarissa.app/api/" : "https://www.sarissa.app/api/",
  withCredentials: false,
});


request.interceptors.request.use(function(request){
  request.headers = {
    ...headerDetail,
    "X-channel": window.innerWidth < 600 ? "mobile" : "desktop",
    "X-Token": localStorage.getItem("u-t")
  };
  return request;
});





request.interceptors.response.use(
  function(response) {
    // console.log("response geldi");
    return response;
  },
  async function(error) {
    if(error && error.response && error.response.status === 401){
      console.log("401 döndü");
      localStorage.clear();
      sessionStorage.clear();
      if(!window.location.includes("giris-yap")) window.location.href = "/giris-yap";
    }
    return Promise.reject(error);
  }
);


export default request;

