const defaultState = {
  modal: false,
  defaultModal: false,
  loading: false,
  backButton: false
};

function elements(state = defaultState, action){
  switch(action.type){
    case "SET_DEFAULT_MODAL": 
      return {
        ...state,
        defaultModal: action.payload
      }
      case "SET_LOADING": 
        return {
          ...state,
          loading: action.payload
        }
      case "SET_MODAL": 
        return {
          ...state,
          modal: action.payload
        }

      case "SET_BACK_BUTTON": 
        return {
          ...state,
          backButton: action.payload
        }
    default: 
      return state;
  }
}

export default elements;
