import React from "react";
import classcat from "classcat";

import c from "./button.module.css";


const Button = ({
  text,
  className,
  onClick,
  secondary,
  disabled,
  style,
  white,
  icon,
  children
}) => {

  return (
    <button  
      onClick={onClick}
      className={classcat([
        c.button,
        className,
        secondary && c.secondary,
        white && c.white,
        disabled && c.disabled
      ])}
      disabled={disabled}
      style={style}
    >
      {
        icon && <img src={icon} alt=""/>
      }
      {text}
    </button>
  );
};

export default Button;