// const defaultState = localStorage.getItem("u-d") ? JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("u-d")))) : false;


function user(state = false, action){
  switch(action.type){
    case "SET_USER": 
      return action.payload
    case "SET_ADDRESS_LIST": 
      return {
        ...state,
        addressList: action.payload
      }
    case "SET_FAVORITES":  
    
      return {
        ...state,
        favorites: action.payload
      } 
    default: 
      return state;
  }

  
}

export default user;
