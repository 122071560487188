import React from "react";
import { Link, useHistory } from "react-router-dom";

import Button from "./../../Button";

import userIcon from "./../../../images/user.svg";
import downArrow from "./../../../images/down-arrow.svg"; 


import d from "./desktopNav.module.css";
import { useSelector } from "react-redux";

 

const DesktopNav = ({userData, onLogout}) => {
  const history = useHistory();
  const config = useSelector(state => state.config);
  
  return (
    <div className={d.desktopNav}>
      <div className={d.content}>
        <div className={d.left}>{config.config.desktop_notification}</div>
        {
          !userData &&
            <div className={d.right}>
              <Link 
                to="/giris-yap"
                className={d.login}>
                Üye Girişi
              </Link>
              <Link 
                to="/kayit-ol"
                className={d.register}>
                Kayıt Ol
              </Link>
          </div>
        }
        {
          userData &&
            <div className={d.loggedIn}>
              <img src={userIcon} alt=""/>
              <span className={d.username}>
                {userData.name} {userData.surname}
              </span>
              <img className={d.arrow} src={downArrow} alt=""/>

              <div className={d.popup}>
                <div className={d.top}>
                  <div>
                    Merhaba 
                    <br />
                    <span className={d.userName}>
                      {userData.name} {userData.surname}
                    </span>
                  </div>
                  <Button
                    className={d.profileButton}
                    text="Profilim"
                    onClick={() => history.push("/profilim")}
                  />
                </div>
                <div className={d.bottom}>
                  <Link to="/siparislerim">Siparişlerim</Link>
                  <Link to="/adreslerim">Adreslerim</Link>
                  <Link to="/favorilerim">Favorilerim</Link>
                  <span className={d.logout} onClick={onLogout} >Çıkış Yap</span>
                </div>
              </div>

            </div>
        }

      </div>
    </div>
  )
}



export default DesktopNav;