import { createStore, combineReducers } from "redux";

import elements from "./reducers/elements";
import user from "./reducers/user";
import config from "./reducers/config";
import cart from "./reducers/cart";
import rawCart from "./reducers/rawCart";


const reducers = combineReducers({
  elements,
  user,
  config,
  cart,
  rawCart
});

const store = createStore(reducers);

export default store;