import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import classcat from "classcat";


import { useDispatch, useSelector } from "react-redux";
import { resetCart } from "../../actions/cart";
import { setUser } from "./../../actions/user";

import DesktopNav from "./DesktopNav";
import LeftPanel from "./../LeftPanel";
import CartField from "./CartField"; 
import SearchField from "./SearchField"; 
import UserField from "./UserField";


import sarissaLogo from "./../../images/sarissa-logo.svg";
import sarissaText from "./../../images/sarissa-text.svg"; 


import c from "./header.module.css";
import { saveCartData } from "./../../services";
import CategoryField from "./CategoryField";
import { setBackButton, setLoading } from "../../actions/elements";



const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
 
  const backButtonStatus = useSelector(state => state.elements.backButton);
  const userData = useSelector(selector => selector.user);
  const [popupStatus, setPopupStatus] = useState(false);

  const cart = useSelector(state => state.cart); 
  const rawCart = useSelector(state => state.rawCart); 

  const config = useSelector(selector => selector.config); 
  const [leftnav, setLeftnav] = useState(false);

  const [focusedCategory, setFocusedCategory] = useState(config.categories[0]); 


  setTimeout(() => {
    setFocusedCategory(config.categories[0]);
  }, 1000);

  
  // useEffect(() => {
    // window.onscroll = function() {
    //   if(document.body.scrollTop > 70 || document.documentElement.scrollTop > 70){
    //     if(!window.shrink){
    //       setShrink(true);
    //       window.shrink = true;
    //     }   
    //   } else {
    //     if(window.shrink){
    //       setShrink(false);
    //       window.shrink = false;
    //     }  
    //   }
    // }
  // }, []);
 

  useEffect(() => {
    document.body.style.overflow = leftnav ? "hidden" : "initial";
  }, [leftnav]);  //eslint-disable-line


  useEffect(() => {
    if(userData && !window.disableSaveRawCart){ 
      dispatch(setLoading(true));
      saveCartData({
        cart: JSON.stringify(rawCart)
      })
      .then(res => {
        if(window.productList) setPopupStatus(true);
        
      })
      .finally(() => dispatch(setLoading(false)))
    }
    window.disableSaveRawCart = false;
  }, [rawCart]); //eslint-disable-line


  const toggleNav = () => {
    if(backButtonStatus){
      history.push(backButtonStatus);
      dispatch(setBackButton(false));
    }else{  
      setLeftnav(!leftnav);
    }
  }

  
  const handleLogout = function () {
    dispatch(setUser(false));
    dispatch(resetCart(false));
    localStorage.clear(); 
    history.push("/giris-yap");
  }


  // const handleUserClick = () => {
  //   history.push("/giris-yap");
  // }
  

  let cartLength = 0;

  rawCart.forEach(p => {
    cartLength += parseInt(p.quantity);
  });
    


  return (
    <nav className={classcat([c.header, false && c.shrink])} >
      <DesktopNav 
        userData={userData}
        onLogout={handleLogout}
      />
      <CategoryField 
        value={leftnav}
        category={focusedCategory}
      />

      <div className={c.content}>
        <div className={c.left}>

        <div 
          onClick={toggleNav}
          className={classcat([
            c.menuToggle,
            leftnav && c.active,
            backButtonStatus && c.backButton
          ])}
        >
          <span></span>
          <span></span>
          <span></span>
        </div> 
          
          <Link to="/" className={c.logoField}>
            <img 
              className={c.logo}
              src={sarissaLogo}  
              alt=""  
            />
            <img 
              className={c.logoText}
              src={sarissaText}  
              alt=""  
            />
          </Link>
        </div>
        <div className={c.center}>
          {
            config.categories.map((mainCategory, index) => { 
              const isActive = window.location.pathname.includes(mainCategory.category_id);
              return (
                <div 
                  to={"/" + mainCategory.category_id}
                  className={classcat([c.mainCategory, isActive && c.active])} 
                  key={"header-main-category-"+index}
                >
                  <Link to={"/" + mainCategory.category_id} >{mainCategory.name}</Link>
                  <div 
                    className={c.subcategoryContainer}
                  >
                    {
                      mainCategory.sub.filter(m => m.main_category_id === mainCategory.id).map(category => {
                        return  <Link 
                                  key={"category-" + category.category_id}
                                  to={"/" + mainCategory.category_id + "/" + category.category_id}
                                >  
                                  {category.name}
                                </Link>
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>

        <div className={c.right}>
          {/* <img 
            onClick={handleUserClick}
            className={c.userIcon}
            src={userIcon}
            alt=""
          /> */}


          <SearchField />
          <UserField userData={userData}/> 
          
          <CartField 
            cart={cart}
            rawCart={rawCart}
            cartLength={cartLength}
            popupStatus={popupStatus}
            setPopupStatus={setPopupStatus}

          />

        </div>

        <LeftPanel 
          status={leftnav}
          setStatus={setLeftnav}
        />
      </div>
    </nav>
  )
};





 /*

const MenuButton = ({onClick, value}) => {
  
 
  return(
    <div 
      onClick={onClick}
      className={classcat([
        c.menuToggle,
        value && c.active
      ])}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

*/

export default Header;