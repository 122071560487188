import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './main';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './store'


import Loading from "./components/Loading";

window.getBaseUrl = url => {
  return (window.env === 'test' ? "https://test.sarissa.app/" : "https://www.sarissa.app/") + url;
} 

window.isMobile = function() {
  return window.innerWidth < 600; 
} 

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loading />}>
      <Provider store={store}>
        <App />
      </Provider>
    </React.Suspense>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//Popupların dışarı tıklandığında kapansın diye
window.isDescendant = (el, parentId) => {
  let isChild = false

  if (el.id === parentId) { //is this the element itself?
    isChild = true
  }

  while (el = el.parentNode) { //eslint-disable-line
    if (el.id == parentId) { //eslint-disable-line
      isChild = true
    }
  } 
  return isChild
}
