export const setConfig = (payload) => {
  return {
    type: "SET_CONFIG",
    payload
  }
}


export const setFilterOptions = (payload) => {
  return {
    type: "SET_FILTER_OPTIONS",
    payload
  }
}
 

export const setStories = (payload = []) => {
  return {
    type: "SET_STORIES",
    payload: payload.map(story => {
      const flag = story.pictures.find(s => !s.isReaded);
 
      story.isReaded = !flag; 

      return story;
    }).sort((a) => a.isReaded ? 1 : -1 )
  }
}


export const setIsReaded = (payload) => {
  return {
    type: "SET_AS_READED",
    payload
  }
}


export const setSeoContent = (payload) => {
  return {
    type: "SET_SEO_CONTENT",
    payload
  }
}