import React, { useEffect, useState } from "react";
import classcat from "classcat";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import CookieConsent from "./../Cookie";
import c from "./footer.module.css";

import facebook from "./../../images/facebook.svg";
import twitter from "./../../images/twitter.svg";
import instagram from "./../../images/instagram.svg";
import youtube from "./../../images/youtube.svg";
import linkedn from "./../../images/linkedin.png";
// import playstore from "./../../images/playstore.svg";
// import appstore from "./../../images/appstore.svg";
import siteLogo from "./../../images/sarissa-logo.png";
import footerLogo from "./../../images/footer-logo.png";


export default function Footer(){
  const cookieConsetStatus = localStorage.getItem("cookie_consent");
  const [cookieStatus, setCookieStatus] = useState(cookieConsetStatus);

  useEffect(() => {
    localStorage.setItem("cookie_consent", "true");
  }, []);

  const config = useSelector(state => state.config.config);
  return (
    <footer className={c.footer}>
      {
        (!cookieConsetStatus && !cookieStatus) && 
          <CookieConsent onClose={() => setCookieStatus(true)}/>
      }
      <div className={c.content}>

        <section className={c.section} >
          <Link className={c.link} to="/sarissa-company">Sarissa Company</Link>
          <Link className={c.link} to="/iletisim">İletişim</Link>
          <Link className={c.link} to="/hakkimizda">Hakkımızda</Link>
          <Link className={c.link} to="/kariyer">Kariyer</Link>
          <Link className={c.link} to="/siparis-sorgula">Sipariş Sorgulama</Link>
          <div className={c.desktopSocial}>
            <b>Sosyal Bağlantılar</b>
            <div className={c.socialLinks}>
              {
                config.facebook &&
                <a target="_blank" rel="noreferrer" href={config.facebook}>
                  <img src={facebook}  alt="" />
                </a>
              }
              {
                config.twitter &&
                <a target="_blank" rel="noreferrer" href={config.twitter}>
                  <img src={twitter}  alt="" />
                </a>
              }
              {
                config.instagram &&
                <a target="_blank" rel="noreferrer" href={config.instagram}>
                  <img src={instagram}  alt="" />
                </a>
              }
              {
                config.youtube &&
                <a target="_blank" rel="noreferrer" href={config.youtube}>
                  <img src={youtube}  alt="" />
                </a>
              }
              {
                config.linkedn &&
                <a target="_blank" rel="noreferrer" href={config.linkedn} style={{width: 20}}>
                  <img src={linkedn}  alt="" />
                </a>
              }    
            </div>
          </div>
        </section>

        <section className={c.section} >
          <Link className={c.link} to="/iade-ve-degisim">İade ve Değişim</Link>
          <Link className={c.link} to="/sss">Sıkça Sorulan Sorular</Link>
          {/* <Link className={c.link} to="/beden-tablosu">Bedenini Keşfet</Link> */}
          <Link className={c.link} to="/gorus-ve-onerileriniz">Görüş ve Önerileriniz</Link>
          <Link className={c.link} to="/bize-ulasin">Bize Ulaşın</Link>
          <div className={c.mobileSocialContainer}>
            {
              config.facebook &&
              <a target="_blank" rel="noreferrer" href={config.facebook}>
                <img src={facebook}  alt="" />
              </a>
            }
            {
              config.twitter &&
              <a target="_blank" rel="noreferrer" href={config.twitter}>
                <img src={twitter}  alt="" />
              </a>
            }
            {
              config.instagram &&
              <a target="_blank" rel="noreferrer" href={config.instagram}>
                <img src={instagram}  alt="" />
              </a>
            }
            {
              config.youtube &&
              <a target="_blank" rel="noreferrer" href={config.youtube}>
                <img src={youtube}  alt="" />
              </a>
            }
            {
              config.linkedn &&
              <a target="_blank" rel="noreferrer" href={config.linkedn} style={{width: 20}}>
                <img src={linkedn}  alt="" />
              </a>
            }    
          </div>
        </section>

        <section className={c.section} > 
          <h4 className={c.footerTitle}>İletişim</h4>
          <span className={c.link}>Çalışma Saatleri</span>
          <span className={c.link}>{config.working_hours}</span>
          <a className={c.link} href={"tel:02125054080" }>{config.phone}</a>
          <a className={c.link} href={"mailto:" + config.mail}>{config.mail}</a>
          {/* <div className={c.appLinks}>
            <a href="/">
              <img src={appstore} alt="" />
              App Store
            </a>
            <a href="/">
              <img src={playstore} alt=""/>
              Play Store
            </a>
          </div> */}
        </section>

        <section 
          className={classcat([c.section, c.lastSection])} 
        >

          <Link to="/">
            <img src={siteLogo} className={c.siteLogo} alt="" />
          </Link>
          <b className={c.link}>© {new Date().getFullYear()} Sarissa</b>
          <Link to="/uyelik-sozlesmesi" className={c.link} >Sarissa Üyelik Sözleşmesi</Link>
          <Link to="/cerez-politikasi" className={c.link} >Sarissa Çerez Politikası</Link>
          <Link to="/kvkk-aydinlatma-metni" className={c.link}>KVKK Aydınlatma Metni</Link>
          <a className={c.tekansLogoField} target="_blank" rel="noreferrer" href="https://www.tekans.com/">
            <img className={c.tekansLogo} src={footerLogo}  alt=""/>
          </a>
          <img  
            style={{maxWidth: 200, marginTop: 10}} 
            src={require("./../../images/iyzico-footer.png").default} 
            alt=""  
          />
        </section>

      </div>
    </footer>
  )
};

 
