import React, { useState, useEffect } from "react"; 
import { useHistory } from "react-router-dom"; 

import userIcon from "./../../../images/user/user.svg";


// import AddressIcon from "./../../../images/address-page";
// import CardIcon from "./../../../images/card/card";
// import UserIcon from "./../../../images/user/user-profiletab";
// import TruckIcon from "./../../../images/truck";
// import HearthIcon from "./../../../images/hearth/hearth";

import c from "./userField.module.css";
 

const UserField = ({userData}) => { 
  const history = useHistory();

  const [status, setStatus] = useState(false); 
   
  useEffect(() => {
    if(status){
      window.onclick = e => {  
        // if(!window.isDescendant(e.target, "user-popup") && e.target.tagName !== "INPUT")
          setStatus(false);
      }
    }else{
      window.onclick = null;
    }
  }, [status]);
  
  
  return (
    <div 
      onClick={() => history.push(userData ? "/profilim" : "/giris-yap")}
      className={c.container}
    > 
      <img 
        src={userIcon} 
        style={{height: 10, cursor: "pointer"}}
        alt=""
      />  
    </div>
  )
}
 
  



export default UserField;



  
// <div className={classcat([c.dropdown, status && c.active]) } id="user-popup">
// <Link to="/profilim">
//   {/* <UserIcon /> */}
//   <span>Profilim</span>
// </Link>
// <Link to="/siparislerim">
//   {/* <TruckIcon /> */}
//   <span>Siparişlerim</span>
// </Link>
// <Link to="/adreslerim">
//   {/* <AddressIcon /> */}
//   <span>Adreslerim</span>
// </Link>
// <Link to="/favorilerim">
//   {/* <HearthIcon /> */}
//   <span>Favorilerim</span>
// </Link>
// <div className={c.logout} onClick={handleLogout} >Çıkış Yap</div> 

// </div>
