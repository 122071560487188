let defaultState = [];

// if(localStorage.getItem("u-c"))
//   defaultState = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("u-c"))));

let currentProduct, product, newCart;

function cart(state = defaultState, action){
  switch(action.type){ 
    case "RESET_CART": 
      return [];

    case "SET_RAW_CART": 
      return action.payload;

    case "ADD_TO_CART":   
      product = action.payload;  
      newCart = [...state];

      currentProduct = newCart.find(c => c.sku === product.sku && c.variant === product.variant);
      if(currentProduct){
        currentProduct.quantity += product.quantity;
      }else{
        newCart.push(action.payload);
      } 
        
  
      return newCart;
 
    case "SET_QUANTITY":    
      product = action.product; 

      newCart = []; 

      state.forEach(p => { 
        if(p.sku === product.sku && p.variant === product.variant){ 
          p.quantity = action.quantity; 
        }
        
        if(p.quantity !== 0)
          newCart.push(p);
      });

      return newCart;
 
    case "DELETE_PRODUCT":  
      newCart = [...state]; 
      
      newCart = newCart.filter(p => p.sku !== action.product.sku || p.variant !== action.product.variant); 

      return newCart;
    
    case "SET_CART_GIFT": 
      newCart = [...state];
      
      product = newCart.find(p => p.sku === action.product.sku && p.variant === action.product.variant);
      if(!product) return state;

      console.log("@sepete eklenen product",product);
      product.gift = action.product.gift;

      return newCart;
      
    default: 
      return state;
  }


  

  
}

export default cart;
