import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
// import CookieConsent from "./components/Cookie";

import { setConfig } from "./actions/config";
import sendRequest, { getConfig } from "./services";

import Modal from "./components/Modal";
import DefaultModal from "./components/DefaultModal";  
import { setErrorModal } from "./actions/elements";
import { setUser } from "./actions/user";
import { setRawCart } from "./actions/cart";

const Home = React.lazy(() => import("./screens/Home"));
const ProductList = React.lazy(() => import("./screens/ProductList"));
const ProductPage = React.lazy(() => import("./screens/Product"));
const CartPage = React.lazy(() => import("./screens/CartPage"));
const PaymentPage = React.lazy(() => import("./screens/PaymentPage"));
const PaymentSuccess = React.lazy(() => import("./screens/PaymentSuccess"));

const Login = React.lazy(() => import("./screens/Login"));
const Register = React.lazy(() => import("./screens/Register"));
const ForgotPassword = React.lazy(() => import("./screens/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./screens/ResetPassword"));


const Verification = React.lazy(() => import("./screens/Verification"));
const Profile = React.lazy(() => import("./screens/Profile"));
const AddressList = React.lazy(() => import("./screens/Profile/Inner/AddressList"));
const SavedCards = React.lazy(() => import("./screens/SavedCards"));
const Favorites = React.lazy(() => import("./screens/Favorites"));
const MyOrders = React.lazy(() => import("./screens/MyOrders"));
const OrderDetails = React.lazy(() => import("./screens/OrderDetails"));
const OrderSearch = React.lazy(() => import("./screens/OrderSearch"));
const ChangePassword = React.lazy(() => import("./screens/ChangePassword"));

const ContactInfo = React.lazy(() => import("./screens/ContactInfo"));
const ContactForm = React.lazy(() => import("./screens/ContactForm"));
const FeedbackForm = React.lazy(() => import("./screens/FeedbackForm"));
const Cookie = React.lazy(() => import("./screens/Cookie"));
const MembershipContract = React.lazy(() => import("./screens/MembershipContract"));
const KVKKPage = React.lazy(() => import("./screens/KVKKPage"));
const About = React.lazy(() => import("./screens/About"));
const NotFoundPage = React.lazy(() => import("./screens/NotFoundPage"));
const SSS = React.lazy(() => import("./screens/SSS"));
const Carrier = React.lazy(() => import("./screens/Carrier"));
const HTMLPage = React.lazy(() => import("./screens/HTMLPage"));


 
function orderCategories(categories) {
  const returnVal = [];

  categories.forEach(m => {
    let main;
    if(m.type === "main"){
      main = m;
      main.sub = [];

      categories.forEach(s => {
        let sub;
        if(s.type === "sub" && s.main_category_id === main.id){
          sub = s;
          sub.childs = []; 
          categories.forEach(c => {
            let child = c;
            if(c.type === "child" && c.main_category_id === sub.id)
              sub.childs.push(child);
          });
        }

        if(sub) main.sub.push(sub);
      });
    }
    
    if(main) returnVal.push(main);
  });
  
  
  return returnVal;
}





export default function App() {
  const dispatch = useDispatch();
  const loading = useSelector(selector => selector.elements.loading);
  const config = useSelector(selector => selector.config);
  const [ready, setReady] = useState(false);
  
  if(!localStorage.getItem("desktopLayout")) localStorage.setItem("desktopLayout", "4");

  
  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          getConfig(),
          sendRequest.post("consumer/get_consumer", {token: localStorage.getItem("u-t")})
        ]); 

        const config = {}; 
        res[0].data.config.forEach(c => {
          config[c.setting_key] = c.value;
        }); 

        dispatch(setConfig({ 
          ...res[0].data,
          config,
          categories: orderCategories(res[0].data.categories)
        }));
        window.config = config;

        if(res[1].data.status === "OK"){ 
          dispatch(setUser(res[1].data.consumer));

          let cart = res[1].data.consumer.cart;
          if(cart){ 
            window.disableSaveRawCart = true;
            dispatch(setRawCart(JSON.parse(cart)));
          } 
        }else{
          localStorage.clear();
        }
 
        setReady(true);
      } catch (error) {  
        dispatch(setErrorModal());
      }
    })(); 
  }, []); //eslint-disable-line
  
  window.setSeoTitle =  async function(page) {
    try {
      if(!config.seo){
        // const res = await sendRequest.get("get_seo_content"){}
      }
      console.log(config.seo);
    } catch (error) {
      
    }
  }
 

  if(!ready) return <Loading />

  return (
    <Router >
      <Header />

      <Switch>
        <Route path="/sarissa-company">
          <HTMLPage 
            title="Sarissa Company" 
            url="sarissa-company"
            contentId="sarissa_company" 
          />
        </Route>
        <Route path="/iade-ve-degisim" >
          <HTMLPage 
            title="İade ve Değişim"
            url="iade-ve-degisim"
            contentId="return_policy"
          />
        </Route>

  

        <Route path="/giris-yap" component={Login} exact/>
        <Route path="/kayit-ol" component={Register} exact/>
        <Route path="/sifremi-unuttum" component={ForgotPassword} exact/>
        <Route path="/sifre-islemleri" component={ChangePassword} exact/>
        <Route path="/sifre-yenile/:token" component={ResetPassword} exact/> 
        <Route path="/hesap-dogrulama/:accountId" component={Verification} exact/> 

        <Route path="/profilim" component={Profile} exact/> 
        <Route path="/kartlarim" component={SavedCards} exact/> 
        <Route path="/favorilerim" component={Favorites} exact/> 
        <Route path="/adreslerim" component={AddressList} exact/> 
        <Route path="/siparislerim" component={MyOrders} exact/> 

        <Route path="/sepetim" component={CartPage} exact/> 
        <Route path="/siparisi-tamamla" component={PaymentPage} exact/> 
        <Route path="/siparis-basarili" component={PaymentSuccess} exact/> 

        <Route path="/bize-ulasin" component={ContactForm} exact/> 
        <Route path="/gorus-ve-onerileriniz" component={FeedbackForm} exact/> 

        <Route path="/iletisim" component={ContactInfo} exact/> 
        <Route path="/kariyer" component={Carrier} exact/> 
        <Route path="/siparis-sorgula" component={OrderSearch} exact/> 
        <Route path="/cerez-politikasi" component={Cookie} exact/>
        <Route path="/kvkk-aydinlatma-metni" component={KVKKPage} exact/> 
        <Route path="/sss" component={SSS} exact/>  
        <Route path="/uyelik-sozlesmesi" component={MembershipContract} exact/>
        <Route path="/hakkimizda" component={About} />

        <Route path="/siparislerim/:orderId" component={OrderDetails} exact/> 

        <Route path="/sayfa-bulunamadi" component={NotFoundPage}/>  
        <Route path="/" component={Home} exact/>  

        <Route path="/:category" component={ProductList} exact/>  
        <Route path="/:category/:subcategory" component={ProductList} exact/>  
        <Route path="/:category/:subcategory/:productId" component={ProductPage} exact/>  
        <Route path="/:category/:subcategory/:productId/:color" component={ProductPage} exact/>  

            
      </Switch>
      <Footer />
      {
        loading && <Loading />
      } 
      <DefaultModal />
      <Modal />
    </Router>
  );
}

