export const setLoading = (payload) => {
  return {
    type: "SET_LOADING",
    payload
  }
}


export const setModal = (payload) => {
  return {
    type: "SET_MODAL",
    payload
  }
} 

export const setDefaultModal = (payload) => {
  return {
    type: "SET_DEFAULT_MODAL",
    payload
  }
} 

export const setSuccessModal = (payload) => {
  return {
    type: "SET_DEFAULT_MODAL",
    payload: {
      icon: require("./../images/success/success-black.svg").default,
      button: "Kapat",
      ...payload
    }
  }
} 


export const setErrorModal = (payload) => {
  return {
    type: "SET_DEFAULT_MODAL",
    payload: {
      // icon: require("./../images/warning.png").default,
      icon: require("./../images/error.svg").default,
      button: "Tamam",
      ...payload,
      title: (payload && payload.title && payload.title.length !== 0) ? payload.title : "Bir Hata Oluştu",
      message: (payload && payload.message && payload.message.length !== 0) ? payload.message : "Beklenmedik bir hata oluştu",
    }
  }
}


export const setWarningModal = (payload) => {
  return {
    type: "SET_DEFAULT_MODAL",
    payload: { 
      icon: require("./../images/error.svg").default,
      button: "Tamam",
      ...payload
    }
  }
}

export const setBackButton = (payload) => {
  return {
    type: "SET_BACK_BUTTON",
    payload
  }
}