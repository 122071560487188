// import React  from "react";

// import c from "./field.module.css";

 
 

const CategoryField = ({
  category
}) => {
  if(!category) return false;
 
  return false;
  // return (
  //   <div className={c.container}>
  //     <div className={c.content}>
  //       {
  //         category.sub.filter(c => c.type == "sub").map((current, index )=> {
  //           return  <CategoryBlock 
  //                     key={"desktop-category-block-"+index}
  //                     current={current}
  //                     categories={category.sub}
  //                   />
  //         })
  //       } 
  //       <div className={c.sideImage} style={{marginLeft: "4vw"}}>
  //         <div>Text</div>
  //         <img  />
  //       </div>

  //       <div className={c.sideImage}>
  //         <div>Text</div>
  //         <img  />
  //       </div>
  //     </div>
  //   </div>
  // )
}

// const CategoryBlock = ({current, categories}) => {
//   return(
//     <div className={c.category}>
//       <span className={c.mainCategory}>{current.name}</span>
//       {/* <span className={c.altCategory}>{current.name}</span> */}
      
//     </div>
//   );
// }



export default CategoryField;