const defaultState = {
  initialized: false,
  categories: [],
  stories: [],
  filterOptions: false,
  seo: {},
}

function categories(state = defaultState, action){
  switch(action.type){
    case "SET_CONFIG": 
      return {
        ...state,
        ...action.payload
      }
    case "SET_FILTER_OPTIONS": 
      return {
        ...state,
        filterOptions: action.payload
      }
    case "SET_STORIES":  
      return {
        ...state,
        stories: action.payload
      }
    case "SET_AS_READED": 
      let tempStories = [...state.stories];
      const story = tempStories.find(s => s.id === action.payload.storyId);

      if(story){
        const picture = story.pictures.find(p => p.id == action.payload.pictureId); //eslint-disable-line
        picture.isReaded = true;

        const flag = story.pictures.find(s => !s.isReaded); 

        if(!flag)
          story.isReaded = true;
      }
      
      return {
        ...state,
        stories: tempStories
      }
    case "SET_SEO_CONTENT": 
      return {
        ...state,
        seo: action.payload
      }
    default: 
      return state;
  }
}

export default categories;
